
import {
  attachments,
  ICompany,
  IGroup,
  IProjectAddRequest,
  LicenseType,
  projects,
  storage,
} from "@/api";
import { Actions, Mutations, ProgressPanelState } from "@/store/models";
import { byteToGB } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "UploadProjectDialog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      selectedZipFile: null as File | null, // Allow null or File type
    };
  },
  computed: {
    company: function (): any {
      return this.$store.state.company;
    },
  },
  methods: {
    onFileChange(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      if (inputElement.files && inputElement.files[0]) {
        const file = inputElement.files[0];
        //this.selectedZipFile = inputElement.files[0];

        // Rename the file if it has a ".fsn" extension
        if (file.name.endsWith(".fsn")) {
          (file as any).name = file.name.replace(".fsn", ".zip");
        }

        this.selectedZipFile = file;
      }
    },
    async uploadFSN() {
      if (!this.selectedZipFile) {
        return;
      }

      if (this.selectedZipFile?.name.split(".").pop() !== "fsn") {
        this.$store.dispatch(
          Actions.DisplayError,
          "Unable to upload this project, it is an unsupported file type."
        );
        return;
      }
      if (!(await this.freeTierCheckPassed())) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Selected file exceeds storage limit, please select a new file."
        );
        return;
      }

      try {
        this.$store.commit(Mutations.SetProgressPanel, {
          text: "Project Upload In Progress",
          state: ProgressPanelState.InProgress,
        });
        this.$emit("update:dialog", false);

        // Upload the ZIP file containing multiple projects
        // mock ->
        // const sleep = (ms: number) => new Promise(
        //   resolve => setTimeout(resolve, ms));
        // await sleep(5000)
        // console.log(this.selectedZipFile)
        await attachments.projects.uploadFSNZip({ data: this.selectedZipFile });

        this.$emit("uploaded");
        this.$store.commit(Mutations.SetProgressPanel, {
          text: "Project Upload Completed",
          state: ProgressPanelState.Completed,
        });
      } catch (e: any) {
        console.log(e);
        // ignore 504 gateway timeout
        if (e.statusCode === 504) {
          return;
        }
        this.$store.commit(Mutations.SetProgressPanel, {
          text: "Project Upload Failed",
          state: ProgressPanelState.Failed,
        });
      } finally {
        this.clearForm();
      }
    },

    async freeTierCheckPassed(): Promise<boolean> {
      if (this.$store.getters.licenseType !== LicenseType.Free) {
        return true;
      }
      const { data } = await storage.getNumbers();
      const fileSize = byteToGB(this.selectedZipFile!.size);
      const storageLeft = data.storage_limit - data.storage_used;
      if (fileSize > storageLeft) {
        return false;
      }
      return true;
    },

    clearForm() {
      this.selectedZipFile = null;
    },
    cancel() {
      this.clearForm();
      this.$emit("update:dialog", false);
    },
  },
  watch: {},
});
