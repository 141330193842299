
import Vue from "vue";
import { IAssociate, IAssociateAssociatesGroup, IGroup } from "@/api";
import UserIcon from "../common/UserIcon.vue";

export default Vue.extend({
  name: "DashboardGroups",
  components: { UserIcon },
  data() {
    return {};
  },
  computed: {
    id: function (): number {
      return this.$store.state.associate.id;
    },
    myGroups: function (): IGroup[] {
      if ((this as any).isGssiAdmin) {
        return this.$store.state.company?.groups;
      }
      const groups: IAssociateAssociatesGroup[] =
        this.$store.state.company?.associates?.find((a: IAssociate) => {
          return a.id === this.id;
        })?.associate_associates_groups || [];
      const set = new Set<number>(groups.map((g) => g.associates_group.id));
      return this.$store.state.company?.groups?.filter((g: IGroup) => {
        return set.has(g.id);
      });
    },
  },
  methods: {
    getAssociate(id: number) {
      return this.$store.state.company.associates.find(
        (a: IAssociate) => a.id === id
      );
    },
    goToGroups() {
      if ((this as any).isGssiAdmin) {
        this.$router.push({ name: "GssiAdminCompanyGroups" });
      } else {
        this.$router.push("/groups");
      }
    },
    goToGroup(id: number) {
      if ((this as any).isGssiAdmin) {
        this.$router.push({ name: "GssiAdminCompanyGroupsUsers" });
      } else {
        this.$router.push(`/groups/${id}/users`);
      }
    },
  },
});
