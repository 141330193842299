
import Vue from "vue";
import { dashboard, IDashboardEquipmentsData, IGroup } from "@/api";
import PieChart from "@/components/common/PieChart.vue";
import { pieGraphColors } from "@/utils";

export default Vue.extend({
  name: "DashboardEquipments",
  components: {
    PieChart,
  },
  data() {
    return {
      groupId: 0,
      total: 0,
      chartData: {
        labels: [] as string[],
        datasets: [
          {
            backgroundColor: [] as string[],
            data: [] as number[],
          },
        ],
      },
    };
  },
  computed: {
    groups: function (): IGroup[] {
      return [
        {
          id: 0,
          name: "All Equipment",
        } as any,
      ].concat(this.$store.state.company.groups || []);
    },
  },
  methods: {
    async getEquipments() {
      let r = {} as IDashboardEquipmentsData;
      if (this.groupId) {
        r = (
          await dashboard.equipments.getByGroup(
            this.groupId,
            (this as any).companyId
          )
        ).data;
      } else {
        r = (await dashboard.equipments.getAll((this as any).companyId)).data;
      }

      const temp = {
        labels: [] as string[],
        datasets: [
          {
            backgroundColor: [] as string[],
            data: [] as number[],
          },
        ],
      };

      r.count_by_device_group.forEach((d, i) => {
        temp.labels.push(d.name);
        temp.datasets[0].data.push(d.count);
        temp.datasets[0].backgroundColor.push(pieGraphColors[i]);
      });

      this.chartData = temp;
      this.total = r.total_device_count!;
    },
  },
  watch: {
    groupId: {
      immediate: true,
      handler: async function () {
        this.getEquipments();
      },
    },
  },
});
