
import Vue from "vue";
import Chart from "@/components/common/Chart.vue";
import { Actions } from "@/store/models";
import {
  dashboard,
  DashboardTimePeriod,
  devices,
  IDashboardGetResponse,
  IDevice,
  IGroup,
} from "@/api";
import { IChartData, Labels } from "@/utils";

const ChartDataDefaults = {
  label: "Projects",
  backgroundColor: "transparent",
  borderColor: "#41a2ef",
  pointBackgroundColor: "white",
  lineTension: 0,
};

export default Vue.extend({
  name: "ProjectsOverview",
  components: {
    Chart,
  },
  data() {
    return {
      timePeriods: Object.keys(DashboardTimePeriod).map((k: any) => ({
        key: k,
        val: DashboardTimePeriod[k as keyof typeof DashboardTimePeriod],
      })),
      timePeriod: DashboardTimePeriod.This_Year,
      devices: [] as IDevice[],
      groups: [] as IGroup[],
      deviceId: 0,
      groupId: 0,
      totalProjectsData: {} as IDashboardGetResponse,
      totalProjectsByData: {} as IDashboardGetResponse,
    };
  },
  computed: {
    totalProjects: function (): IChartData {
      return this.getChartData(this.totalProjectsData);
    },
    totalProjectsBy: function (): IChartData {
      return this.getChartData(this.totalProjectsByData);
    },
  },
  created() {
    this.getTotalProjects();
    this.getDevices();
    this.getGroups();
    if (this.groups?.length && !this.groupId) {
      this.groupId = this.groups[0].id;
    }
  },
  methods: {
    async getTotalProjects() {
      try {
        this.totalProjectsData = await dashboard.projects.getTotalByTimeAndMore(
          this.timePeriod,
          {
            companyId: (this as any).companyId,
          }
        );
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async getTotalProjectsBy() {
      try {
        const { deviceId, groupId } = this;
        this.totalProjectsByData =
          await dashboard.projects.getTotalByTimeAndMore(this.timePeriod, {
            deviceId,
            groupId,
            //groupId: groupId || undefined, //if no group is selected, skip passing the group_id
            companyId: (this as any).companyId,
          });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    getChartData(response: IDashboardGetResponse): IChartData {
      return {
        labels: response?.data?.label ? Labels[response.data.label] : [],
        datasets: [
          {
            data: response?.data?.label
              ? Labels[response.data.label].map(
                  (d: any) =>
                    response.data.data.find((data) => data.label === d)
                      ?.count || 0
                )
              : [],
            ...ChartDataDefaults,
          },
        ],
      };
    },
    async getDevices() {
      this.devices = (
        await devices.getAllByCompany((this as any).companyId)
      ).data;
      this.deviceId = this.devices[0].id;
    },
    getDevice(item: IDevice) {
      let serial = item.device_serial_number;
      let start = "";
      if (serial.startsWith("-")) {
        serial = serial.substring(1);
        start = "-";
      }
      serial = start + serial.padStart(4, "0");
      return `${item.device_type.name}, Serial #${serial}`;
    },
    getGroups() {
      const allProjects = [
        {
          id: 0,
          name: "All Projects",
        } as IGroup,
      ];
      this.groups = allProjects.concat(this.$store.state.company.groups);
    }
  },
  watch: {
    timePeriod: function () {
      this.getTotalProjects();
      this.getTotalProjectsBy();
    },
    deviceId: function () {
      this.getTotalProjectsBy();
    },
    groupId: function () {
      this.getTotalProjectsBy();
    },
    companyId: function (val) {
      if (val) this.getDevices();
    },
    groups: function () {
      if (this.groups?.length && !this.groupId) {
        this.groupId = this.groups[0].id;
      }
    },
  },
});
