
import { dashboard } from "@/api";
import { getDate, getTime } from "@/utils";
import Vue from "vue";
import { getActivity } from "@/utils/activity";

export default Vue.extend({
  name: "DashboardProjectActivityLog",
  components: {},
  props: {},
  data() {
    return {
      items: [] as any,
    };
  },
  created() {
    this.getItems();
  },
  methods: {
    async getItems() {
      this.items = (
        await dashboard.projects.getActivityLogs((this as any).companyId)
      ).data.map((l) => ({
        datetime:
          getTime(l.created_datetime) + " • " + getDate(l.created_datetime),
        activity: getActivity(l),
      }));
    },
  },
});
